import React, { useState, useEffect, useContext } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import Title from '../components/Title';
import SubmitButton from '../components/SubmitButton';
import useGTM from "@elgorditosalsero/react-gtm-hook";
import useGtmEventTracking from '../components/useGtmEventTracking';
import UserContext from '../contexts/UserContext';


interface LandingProps extends RouteComponentProps {
}




function LandingComponent({history}: LandingProps): JSX.Element {
  const [hover, setHover] = useState<number| null>(null);
  const { sendDataToGTM } = useGTM();

  const gtmEventTracking = useGtmEventTracking();
  useEffect(() => {
    gtmEventTracking({event: 'homepage loaded', value: true})
  }, [gtmEventTracking]);

  const active = "rollover";
  const nonActive = "those-freaking-boxes";
  const onMouseLeave = () => setHover(null);

  const { user } = useContext(UserContext);



  function welcomeMessage(  ): JSX.Element {
    let welcomeText: string;
    if ( user ) {
      welcomeText = "Welcome back, " + user.firstName + ". ";
      return (
        <div>
          <span>{welcomeText}  <a href="/dashboard">Manage your account</a></span>


        </div>
      )
    }  else {
      welcomeText = "Returning users please login";
      return (
        <div>
          <Link onClick={() => {
              sendDataToGTM({event: 'login_link'});
            }} to="/login">Login</Link>
          <span><br /><a href="/register">Register</a></span>
        </div>

      )
    }

  }



  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return <div>
    <section className="login-status-bar">
      <div className="status-bar-content">


        <p className="grey"> {welcomeMessage()}</p>








      </div>
    </section>
    <Title>Let's get you moving...</Title>
    <p className="grey">Kit Keeper are experts at storing and shipping boxes all across the UK.</p>
    <div className="landing-all-box-container">
      <LandingBox title="Store Boxes"
                  //priceTag="Store Boxes"
                  description="We provide an all-inclusive service including boxes, packing materials, collection, storage and delivery (one month minimum storage charge)."
                  buttonText="Book Storage"
                  className={ hover === 1 ? active : nonActive }
                  onClick={ () => {
                    history.push('/storage/boxes');
                    sendDataToGTM({event: 'booking_step_option_storage'});
                  } }
                  staticStateImg="/img/box-icon-large.png"
                  rolloverStateImg="/img/box-icon-white-large.png"
                  onMouseEnter={ () => setHover(1) }
                  onMouseLeave={ onMouseLeave }
      />
      <LandingBox title="Ship Boxes"
                  //priceTag="Ship Boxes"
                  description="Our shipping service includes empty boxes, packing materials, collection and delivery of your items to anywhere in the UK."
                  buttonText="Ship Boxes"
                  className={ hover === 2 ? active : nonActive }
                  onClick={ () => {
                    history.push('/shipping/boxes');
                    sendDataToGTM({event: 'booking_step_option_ship'});
                  } }
                  staticStateImg="/img/ship-icon.png"
                  rolloverStateImg="/img/ship-icon-white.png"
                  onMouseEnter={ () => setHover(2) }
                  onMouseLeave={ onMouseLeave }
      />
    </div>
  </div>;
}

interface LandingBoxProps {
  onClick?: () => void,
  onMouseEnter?: () => void,
  onMouseLeave?: () => void,
  title: string,
  priceTag: string,
  description: string,
  buttonText: string,
  className: string,
  staticStateImg: string,
  rolloverStateImg: string,
}

function LandingBox({ description, priceTag, title, buttonText, staticStateImg, rolloverStateImg, ...props }: LandingBoxProps) {
  return (
    <section { ...props }>
      <div className="content">
        <h3>{ title }</h3>
        <img className="static-state" src={ staticStateImg } alt="background" />
        <img className="rollover-state" src={ rolloverStateImg } alt="highlighted background" />
        <div className="jessieJ-priceTag">{ priceTag }</div>
        <p>{ description }</p>
      </div>
      <SubmitButton>
        { buttonText }
      </SubmitButton>
    </section>
  )
}

export default withRouter(LandingComponent);
